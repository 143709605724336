<template>
  <div>
    <training-courses-tab-enrolments-add-new
      v-if="isManager && trainingCourseToEnrol && trainingCourseToEnrol['@id']"
      :is-add-new-user-sidebar-active.sync="isAddNewEnrolmentSidebarActive"
      :training-course-data="trainingCourseToEnrol ? trainingCourseToEnrol : {}"
      :enrolments-data="
        trainingCourseToEnrol ? trainingCourseToEnrol.enrolments : []
      "
      @refetch-data="fetchData"
    />
    <b-sidebar
      v-if="!(trainingCourseToEnrol && trainingCourseToEnrol['@id'])"
      id="add-new-user-sidebar"
      :visible="isAddNewEnrolmentSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
    >
      <template #default>
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Zapisz na szkolenie
          </h5>
        </div>
        <div class="p-2">
          Pobieranie danych...
        </div>
      </template></b-sidebar>
    <!-- Filters -->
    <training-courses-list-filters
      v-if="isAdmin || isFordPoland || isManager || isTrainer"
      :department-filter.sync="departmentFilter"
      :trainer-filter.sync="trainerFilter"
      :status-filter.sync="statusFilter"
      :department-options="departmentOptions"
      :status-options="statusOptions"
      :trainer-options="trainerOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Pokaż</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>szkoleń</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Szukaj"
              />
              <b-button
                v-if="isAdmin"
                variant="gradient-primary"
                to="/training-courses/new"
              >
                <span class="text-nowrap">Dodaj szkolenie</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTrainingCoursesListTable"
        class="position-relative"
        :items="TrainingCoursesList"
        responsive
        :fields="visibleTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nie znaleziono szkoleń spełniających podane kryteria."
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
        tbody-tr-class="cursor-pointer"
        hover
        :per-page="perPage"
        :current-page="currentPage"
        @row-clicked="trainingCourseDetailsRedirect"
      >
        <template
          v-if="!TrainingCoursesList.length"
          #table-busy
        >
          <div class="text-center">
            <b-spinner class="align-middle mr-1" /> Pobieranie listy szkoleń
          </div>
        </template>

        <template #cell(date)="{ item }">
          <div style="white-space: nowrap">
            {{ item.date | date }}<br>{{ item.endDate | date }}
          </div>
        </template>

        <template #cell(time)="{ item }">
          <div style="white-space: nowrap">
            {{ item.date | time }}
          </div>
        </template>

        <template #cell(trainer)="{ item }">
          {{ item.trainer.firstName }} {{ item.trainer.lastName }}
        </template>
        <template #cell(invoices)="{ item }">
          <b-button-group
            v-for="invoice in item.invoices"
            :key="invoice.id"
            size="sm"
            class="w-100 mb-50"
          >
            <b-button
              class="btn-icon"
              :variant="invoice.isPaid ? 'success' : 'danger'"
              :title="invoice.isPaid ? 'Opłacona' : 'Nieopłacona'"
            >
              <feather-icon icon="FileIcon" />
            </b-button>
            <b-button
              class="btn-icon"
              variant="light"
              title="Pobierz"
              @click="downloadInvoice(invoice)"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </b-button-group>
        </template>

        <!-- <template #cell(department)="{ item }">
          {{ resolveDepartment(item.department) }}
        </template> -->

        <template #cell(departments)="{ item }">
          {{ resolveDepartments(item.departments) }}
        </template>

        <template #cell(numberOfParticipants)="{ item }">
          {{ item.numberOfParticipants - item.enrolmentsCount }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${
              resolveTrainingCourseStatus(data.item.status).variant
            }`"
            class="text-capitalize"
          >
            {{
              resolveTrainingCourseStatus(data.item.status).text.toUpperCase()
            }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div style="white-space: nowrap">
            <b-button-group>
              <b-button
                size="sm"
                variant="light"
                :to="'/training-courses/details/' + data.item.id"
              >
                <feather-icon icon="InfoIcon" />
                <span class="align-middle ml-50">Szczegóły</span>
              </b-button>
              <b-button
                v-if="isManager && trainingCourseIsUpcoming(data.item)"
                size="sm"
                variant="primary"
                :disabled="
                  data.item.enrolmentsCount >= data.item.numberOfParticipants
                "
                @click="openEnrolmentSidebar(data.item)"
              >
                <feather-icon icon="UserPlusIcon" />
                <span class="align-middle ml-50">Zapisz na szkolenie</span>
              </b-button>
              <b-button
                v-if="isAdmin || isTrainer"
                size="sm"
                variant="light"
                :to="'/training-courses/edit/' + data.item.id"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edytuj</span>
              </b-button>

              <b-button
                v-if="isAdmin"
                size="sm"
                variant="light"
                @click="trainingCourseToDelete = data.item"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Usuń</span>
              </b-button>
            </b-button-group>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Widok {{ metadata.from }} - {{ metadata.to }} z
              {{ metadata.of }} szkoleń</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTrainingCourses"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      v-if="trainingCourseToDelete"
      v-model="deleteModalOpen"
      centered
      modal-class="modal-danger"
      title="Potwierdź usunięcie"
      ok-variant="danger"
      cancel-variant="light"
      ok-title="Usuń"
      cancel-title="Anuluj"
      @ok="deleteTrainingCourse"
    >
      Na pewno usunąć szkolenie
      <strong>{{ trainingCourseToDelete.name }} </strong>?</b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BButtonGroup,
  BModal,
  BSpinner,
  BSidebar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userRoleMixin from '@/mixins/userRoleMixin'
import resolveDepartment from '@/helpers/resolveDepartment'
import resolveDepartments from '@/helpers/resolveDepartments'
import departmentOptions from '@/helpers/departmentOptions'
import statusOptions from '@/helpers/trainingCourseStatusOptions'
import visibleTableColumns from '@/helpers/visibleTableColumns'
import resolveTrainingCourseStatus from '@/helpers/resolveTrainingCourseStatus'
import trainingCourseIsUpcoming from '@/helpers/trainingCourseIsUpcoming'
import TrainingCoursesListFilters from './TrainingCoursesListFilters.vue'
import TrainingCoursesTabEnrolmentsAddNew from './TrainingCoursesTabEnrolmentsAddNew.vue'

export default {
  components: {
    TrainingCoursesListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BButtonGroup,
    BModal,
    BSpinner,
    BSidebar,
    TrainingCoursesTabEnrolmentsAddNew,

    vSelect,
  },
  mixins: [userRoleMixin],
  setup() {
    const tableColumns = [
      { key: 'name', sortable: true, label: 'Nazwa szkolenia' },
      // { key: 'department', label: 'Dział' },
      { key: 'departments', label: 'Dział' },
      { key: 'date', label: 'Data', sortable: true },
      { key: 'time', label: 'Godzina startu' },
      {
        key: 'trainer',
        label: 'Trener',
        sortable: true,
        visibleTo: [
          'ROLE_ADMIN',
          'ROLE_FORD_POLAND',
          'ROLE_MANAGER',
          'ROLE_EMPLOYEE',
        ],
      },
      { key: 'localization', sortable: true, label: 'Lokalizacja' },
      {
        key: 'numberOfParticipants',
        label: 'Dostępne miejsca',
        sortable: true,
        visibleTo: [
          'ROLE_ADMIN',
          'ROLE_FORD_POLAND',
          'ROLE_TRAINER',
          'ROLE_MANAGER',
        ],
      },
      {
        key: 'enrolmentsCount',
        label: 'Zapisane osoby',
        sortable: true,
        visibleTo: ['ROLE_ADMIN', 'ROLE_FORD_POLAND', 'ROLE_TRAINER'],
      },
      {
        key: 'status',
        label: 'Status szkolenia',
        visibleTo: ['ROLE_ADMIN', 'ROLE_FORD_POLAND', 'ROLE_TRAINER'],
      },
      // { key: 'invoices', label: 'Faktura' },
      { key: 'invoices', label: 'Faktura', visibleTo: ['ROLE_MANAGER'] },
      { key: 'actions', label: 'Akcje' },
    ]

    // Table Handlers
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('date')
    const isSortDirDesc = ref(true)

    return {
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // Filter
      statusOptions,
    }
  },
  data() {
    return {
      trainingCourseToDelete: null,
      trainingCourseToEnrol: null,
      loading: false,
      departmentFilter: null,
      statusFilter: null,
      trainerFilter: null,
      departmentOptions,
      statusOptions,
    }
  },
  computed: {
    isAddNewEnrolmentSidebarActive: {
      get() {
        return !!this.trainingCourseToEnrol
      },
      set(value) {
        this.trainingCourseToEnrol = !!value
      },
    },

    TrainingCoursesList() {
      let list = this.$store.state.trainingCourse.list
        ? this.$store.state.trainingCourse.list['hydra:member']
        : []

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase()
        let found = false

        list = list.filter(item => {
          found = false
          if (item.name.toLowerCase().indexOf(query) !== -1) found = true
          if (item.localization.toLowerCase().indexOf(query) !== -1) { found = true }
          return found
        })
      }

      if (this.statusFilter) {
        list = list.filter(item => item.status === this.statusFilter)
      }

      if (this.departmentFilter) {
        list = list.filter(item => item.departments.includes(this.departmentFilter))
      }

      if (this.trainerFilter) {
        list = list.filter(
          item => item.trainer['@id'] === this.trainerFilter,
        )
      }

      return list
    },

    visibleTableColumns,

    totalTrainingCourses() {
      return this.TrainingCoursesList.length
    },

    metadata() {
      const localItemsCount = this.$refs.refTrainingCoursesListTable
        ? this.$refs.refTrainingCoursesListTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalTrainingCourses,
      }
    },

    deleteModalOpen: {
      get() {
        return !!this.trainingCourseToDelete
      },
      set(value) {
        this.trainingCourseToDelete = value
      },
    },

    trainerOptions() {
      if (
        !this.$store.state.user.list
        || !this.$store.state.user.list['hydra:member']
      ) {
        return []
      }
      const options = []
      this.$store.state.user.list['hydra:member'].forEach(user => {
        options.push({
          label: `${user.firstName} ${user.lastName}`,
          value: user['@id'],
        })
      })

      return options
    },
  },
  mounted() {
    if (this.isManager) {
      this.setManagerFilters()
    }

    this.fetchData()
  },
  methods: {
    resolveTrainingCourseStatus,
    resolveDepartment,
    resolveDepartments,
    trainingCourseIsUpcoming,
    fetchData() {
      this.loading = true
      Promise.all([
        this.$store.dispatch('trainingCourse/fetchList'),
        this.isAdmin || this.isFordPoland
          ? this.$store.dispatch('user/fetchList', {
            params: {
              roles: 'ROLE_TRAINER',
            },
          })
          : null,
      ]).finally(() => {
        this.loading = false
      })
    },
    deleteTrainingCourse() {
      this.$store
        .dispatch('trainingCourse/deleteTrainingCourse', {
          id: this.trainingCourseToDelete.id,
        })
        .then(response => {
          if (response && response.status === 204) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Szkolenie zostało usunięte',
                icon: 'Trash2Icon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się usunąć szkolenia.',
                text: response ? response.data['hydra:description'] : null,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
              timeout: false,
            })
          }
          this.$store.dispatch('trainingCourse/fetchList')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nie udało się usunąć szkolenia.',
              text: error ? error.data['hydra:description'] : null,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
            timeout: false,
          })
        })
    },

    setManagerFilters() {
      this.departmentOptions = this.departmentOptions.filter(option => this.currentUser.departments.includes(option.value))
    },

    trainingCourseDetailsRedirect(trainingCourse) {
      this.$router.push(`/training-courses/details/${trainingCourse.id}`)
    },

    downloadInvoice(invoice) {
      window.open(invoice.downloadUrl)
    },

    openEnrolmentSidebar(trainingCourse) {
      this.loading = true
      this.isAddNewEnrolmentSidebarActive = true
      this.$store.dispatch('user/fetchList')
      this.$store
        .dispatch(
          'trainingCourse/fetchEnrolmentsForTrainingCourse',
          trainingCourse.id,
        )
        .then(response => {
          if (response && response.status === 200) {
            this.trainingCourseToEnrol = trainingCourse
            this.$set(
              this.trainingCourseToEnrol,
              'enrolments',
              response.data['hydra:member'],
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
